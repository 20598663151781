"use client";
import { FirebaseApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import PATHS from '../utils/paths';

export const STARTER_YEARLY_PRICE_ID = "price_1QQducEZfFKA3aBZRH7hfN2h";
export const STARTER_MONTHLY_PRICE_ID = "price_1QQdu2EZfFKA3aBZLed75UnU";
export const BUSINESS_YEARLY_PRICE_ID = "price_1QQdtOEZfFKA3aBZl3La27u8";
export const BUSINESS_MONTHLY_PRICE_ID = "price_1QQds9EZfFKA3aBZcGeNHOJB";

export const getCheckoutUrl = async (
  app: FirebaseApp,
  priceId: string,
): Promise<string> => {
  const auth = getAuth(app);
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User is not authenticated");

  const toltReferral = (window as any).tolt_referral || null;

  const db = getFirestore(app);
  const checkoutSessionRef = collection(
    db,
    "customers",
    userId,
    "checkout_sessions"
  );

  // First attempt with promotion code if there's a referral
  if (toltReferral) {
    try {
      const result = await tryCreateCheckoutSession(checkoutSessionRef, priceId, toltReferral);
      return result;
    } catch (error) {
      if (error instanceof Error && error.message.includes('prior transactions')) {
        // Retry without promotion code
        return tryCreateCheckoutSession(checkoutSessionRef, priceId, toltReferral);
      }
      throw error;
    }
  }

  // No referral, proceed normally
  return tryCreateCheckoutSession(checkoutSessionRef, priceId, toltReferral);
};

const tryCreateCheckoutSession = async (
  checkoutSessionRef: any,
  priceId: string,
  toltReferral: string | null,
): Promise<string> => {
  const docRef = await addDoc(checkoutSessionRef, {
    price: priceId,
    metadata: {
      tolt_referral: toltReferral,
    },
    success_url: `${window.location.origin}${PATHS.PREMIUM_UNLOCKED}`,
    cancel_url: `${window.location.origin}${PATHS.PRICING}`,
    allow_promotion_codes: true,
  });

  return new Promise<string>((resolve, reject) => {
    const unsubscribe = onSnapshot(docRef, (snap) => {
      const { error, url } = snap.data() as {
        error?: { message: string };
        url?: string;
      };
      if (error) {
        unsubscribe();
        reject(new Error(error.message));
      }
      if (url) {
        console.log("Stripe Checkout URL:", url);
        unsubscribe();
        resolve(url);
      }
    });
  });
};

export const getPortalUrl = async (app: FirebaseApp): Promise<string> => {
  const auth = getAuth(app);
  const user = auth.currentUser;

  let dataWithUrl: any;
  try {
    const functions = getFunctions(app, "us-central1");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });

    // Add a type to the data
    dataWithUrl = data as { url: string };
    console.log("Reroute to Stripe portal: ", dataWithUrl.url);
  } catch (error) {
    console.error(error);
  }

  return new Promise<string>((resolve, reject) => {
    if (dataWithUrl.url) {
      resolve(dataWithUrl.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
};