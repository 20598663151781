import { useState, useEffect } from 'react';
import { getChartDates } from '../../../api/client.js';

interface DateOption {
  value: string;
  label: string;
}

export const useChartDates = (chartType: string): DateOption[] => {
  const [dates, setDates] = useState<DateOption[]>([]);

  useEffect(() => {
    const fetchDates = async () => {
      const chartDates = await getChartDates(chartType);
      if (chartDates?.dates) {
        const formattedDates = chartDates.dates.map((date: string) => {
          const dateObj = new Date(date + 'T00:00:00Z');
          return {
            value: date,
            label: dateObj.toLocaleDateString('en-US', { 
              month: 'short',
              year: 'numeric',
              timeZone: 'UTC'
            })
          };
        });
        setDates(formattedDates);
      }
    };

    fetchDates();
  }, [chartType]);

  return dates;
}; 