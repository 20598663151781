import * as React from 'react';
import { Box, Table, Sheet, Link, Typography, Button, Skeleton } from '@mui/joy';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  ArrowDropDown as ArrowDropDownIcon
} from '@mui/icons-material';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (order, orderBy) =>
  order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);

const DataTable = ({ columns, rows, loading }) => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 50;

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const getColumnWidth = (index, column) => {
    const lastIndex = columns.length - 1;
    if (index === lastIndex) {
      return '30%'; // Last column is 30%
    }
    if (index === 0) {
      return '10%'; // First column is 10%
    }
    // Distribute remaining 60% among middle columns
    const middleColumnsCount = columns.length - 2;
    return `${60 / middleColumnsCount}%`;
  };

  const TableHeader = () => (
    <thead>
      <tr>
        {columns.map((column, index) => (
          <TableHeaderCell
            key={column.field}
            column={column}
            index={index}
            orderBy={orderBy}
            order={order}
            onRequestSort={handleRequestSort}
            columnWidth={getColumnWidth(index, column)}
          />
        ))}
      </tr>
    </thead>
  );

  const TableBody = () => {
    if (loading) {
      return (
        <tbody>
          {Array.from({ length: 15 }, (_, index) => (
            <tr key={index}>
              {columns.map((column, colIndex) => (
                <td key={colIndex} style={{
                  paddingLeft: colIndex === 0 ? '24px' : '6px',
                  width: getColumnWidth(colIndex, column),
                }}>
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      height: '24px',
                      width: '90%',
                      transform: 'scale(1)', // Prevents default scaling behavior
                    }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      );
    }

    return (
      <tbody>
        {rows
          ?.slice()
          .sort(getComparator(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => (
            <TableRow
              key={index}
              row={row}
              columns={columns}
              getColumnWidth={getColumnWidth}
            />
          ))}
        {emptyRows > 0 && (
          <tr style={{ height: 53 * emptyRows }}>
            <td colSpan={6} />
          </tr>
        )}
      </tbody>
    );
  };

  return (
    <React.Fragment>
      <Sheet
        variant="outlined"
        sx={{
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          overflow: 'auto',
          minHeight: 0,
          maxWidth: '100%',
        }}
      >
        <Box sx={{ minWidth: 1200, width: '100%' }}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
              width: '100%',
              tableLayout: 'fixed',
            }}
          >
            <TableHeader />
            <TableBody />
          </Table>
        </Box>
      </Sheet>
      <Pagination
        page={page}
        rowsPerPage={rowsPerPage}
        totalRows={rows?.length ?? 0}
        onChangePage={handleChangePage}
      />
    </React.Fragment>
  );
};

const TableHeaderCell = ({ column, index, orderBy, order, onRequestSort, columnWidth }) => (
  <th style={{
    padding: '12px 6px',
    paddingLeft: index === 0 ? '24px' : '6px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: columnWidth,
  }}>
    {column.sortable ? (
      <Link
        underline="none"
        color="primary"
        component="button"
        onClick={() => onRequestSort(column.field)}
        endDecorator={column.sortable !== false && <ArrowDropDownIcon />}
        sx={{
          '& svg': {
            transition: '0.2s',
            transform: orderBy === column.field && order === 'desc'
              ? 'rotate(0deg)'
              : 'rotate(180deg)',
          },
        }}
      >
        {column.headerName}
      </Link>
    ) : (
      <Typography sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>
        {column.headerName}
      </Typography>
    )}
  </th>
);

const TableRow = ({ row, columns, getColumnWidth }) => (
  <tr>
    {columns.map((column, colIndex) => (
      <TableCell
        key={column.field}
        column={column}
        row={row}
        colIndex={colIndex}
        columnWidth={getColumnWidth(colIndex, column)}
      />
    ))}
  </tr>
);

const TableCell = ({ column, row, colIndex, columnWidth }) => (
  <td style={{
    paddingLeft: colIndex === 0 ? '24px' : '6px',
    width: columnWidth,
  }}>
    {column.renderCell
      ? column.renderCell({ value: row[column.field], row })
      : (
        <Typography level="body-sm" noWrap>
          {column.valueFormatter
            ? column.valueFormatter({ value: row[column.field] })
            : row[column.field]
          }
        </Typography>
      )
    }
  </td>
);

const Pagination = ({ page, rowsPerPage, totalRows, onChangePage }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      py: 2,
    }}
  >
    <Button
      size="sm"
      variant="outlined"
      color="neutral"
      startDecorator={<KeyboardArrowLeftIcon />}
      onClick={() => onChangePage(page - 1)}
      disabled={page === 0}
    >
      Previous
    </Button>
    <Typography level="body-sm" mx={2}>
      Page {page + 1} of {Math.ceil(totalRows / rowsPerPage)}
    </Typography>
    <Button
      size="sm"
      variant="outlined"
      color="neutral"
      endDecorator={<KeyboardArrowRightIcon />}
      onClick={() => onChangePage(page + 1)}
      disabled={page >= Math.ceil(totalRows / rowsPerPage) - 1}
    >
      Next
    </Button>
  </Box>
);

export default DataTable;