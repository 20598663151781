import React from 'react';
import { formatTotalPrice, formatNumberCompact, formatPath } from '../../utils/helpers.js';
import { TopPage, NameCell } from './TopPage';
import PATHS from '../../utils/paths.js';
import CustomChip from '../../components/CustomChip.tsx';
import { useChartDates } from './hooks/useChartDates.ts';

const showAvatar = true;

const columns = [
  {
    field: 'id',
    headerName: 'Rank',
    width: 80,
    sortable: true,
  },
  {
    field: 'sellerInfo',
    headerName: 'Seller',
    flex: 2,
    renderCell: (params) => (
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      }}>
        <img
          src={params.value.imageUrl}
          alt={params.value.name}
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '24px',
            objectFit: 'cover'
          }}
        />
        <NameCell 
          name={params.value.name} 
          href={params.row.date 
            ? `/seller/${formatPath(params.value.name)}?date=${params.row.date}`
            : `/seller/${formatPath(params.value.name)}`
          } 
        />
      </div>
    ),
  },
  {
    field: 'totalPrice',
    headerName: 'Sales',
    type: 'number',
    flex: 2,
    sortable: true,
    valueFormatter: ({ value }) => formatTotalPrice(value),
  },
  {
    field: 'soldItemsCount',
    headerName: 'Items Sold',
    type: 'number',
    flex: 2,
    sortable: true,
    valueFormatter: ({ value }) => formatNumberCompact(value),
  },
  {
    field: 'averageSalePrice',
    headerName: 'Average Price',
    type: 'number',
    flex: 2,
    sortable: true,
    valueFormatter: ({ value }) => formatTotalPrice(value),
  },
  {
    field: 'brands',
    headerName: 'Top Brands',
    sortable: false,
    flex: 4,
    renderCell: (params) => (
      <div>
        {params.value
          .slice(0, 3)
          .map((category, index) => (
            <CustomChip
              key={index}
              showAvatar={showAvatar}
              name={category.name}
              count={category.quantity}
              index={index}
            />
          ))}
      </div>
    ),
  },
];

const TopSellers = () => {
  const dates = useChartDates('creators');

  return (
    <TopPage
      type={"creators"}
      columns={columns}
      title={"The Top 100 Sellers on Poshmark"}
      description={"Uncover the highest ranking sellers on Poshmark so you can learn from the best and increase your sales."}
      href={PATHS.TOP_SELLERS}
      toggleOptions={[
        {
          name: 'date',
          label: 'Month',
          placeholder: 'Select a month',
          options: dates,
        },
        {
          name: 'include-luxury',
          label: 'Show Luxury Brand Sellers',
          placeholder: 'Show luxury brands sellers',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
      ]}
      subtitle={
        <div>
          <p>
            Learn from high ranking sellers to improve your listings and make more Poshmark sales.
          </p>
        </div>
      }
    />
  );
};

export default TopSellers;
