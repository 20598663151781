import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { search } from '../../../api/client.js';
import LocalCache from '../../../utils/LocalCache.ts';
import { formatTotalPrice } from '../../../utils/helpers.js';

interface SearchResult {
  availablePosts: any[];
  posts: any[];
  colorDistribution: Distribution;
  sizeDistribution: Distribution;
  styleTagDistribution: Distribution;
  departmentDistribution: Distribution;
  categoryDistribution: Distribution;
  categoryFeatureDistribution: Distribution;
  conditionDistribution: Distribution;
  filterOptions: FilterOptions;
  tokenizedQuery?: {
    brand?: string;
    style?: string;
  };
}

interface Distribution {
  sold: Record<string, number>;
  available: Record<string, number>;
}

type Stat = {
  title: string;
  mainValue: number | string;
  secondaryValue?: number | string;
  mainLabel?: string;
  secondaryLabel?: string;
  description?: string;
  insightLabel?: string;
  insightLevel?: 'neutral' | 'success' | 'warning' | 'danger';
};

export interface DistributionRow {
  id: string;
  name: string;
  sold: number;
  available: number;
}

interface DistributionTables {
  colorRows: DistributionRow[];
  sizeRows: DistributionRow[];
  styleRows: DistributionRow[];
  categoryRows: DistributionRow[];
  departmentRows: DistributionRow[];
  featureRows: DistributionRow[];
}

type UsePostGridReturn = {
  postGridData: any[];
  formatTimeSinceSale: (daysSinceSale: number | null) => string;
  formatTimeToSell: (timeToSell: number | null) => string;
  sortField: string;
  sortDirection: 'asc' | 'desc';
  handleSort: (field: string) => void;
  showAvailable: boolean;
  setShowAvailable: (show: boolean) => void;
};

interface FilterOption {
  slug: string;
  display: string;
  count: number;
}

interface FilterOptions {
  brands: FilterOption[];
  categories: FilterOption[];
  sizes: FilterOption[];
  colors: FilterOption[];
  styles: FilterOption[];
  departments: FilterOption[];
}

interface UseSearchReturn {
  results: SearchResult | null;
  query: string;
  loading: boolean;
  error: string | null;
  errorCode: number | null;
  showSuggestions: boolean;
  stats: Stat[];
  setQuery: (query: string) => void;
  setShowSuggestions: (show: boolean) => void;
  handleSearch: (searchQuery?: string) => void;
  performSearch: (searchQuery: string) => Promise<void>;
  initialSearchDone: React.RefObject<boolean>;
  postGrid: UsePostGridReturn;
  distributions: DistributionTables;
  filterOptions: FilterOptions;
  selectedBrands: string[];
  setSelectedBrands: React.Dispatch<React.SetStateAction<string[]>>;
  selectedCategories: string[];
  setSelectedCategories: React.Dispatch<React.SetStateAction<string[]>>;
  selectedSizes: string[];
  setSelectedSizes: React.Dispatch<React.SetStateAction<string[]>>;
  selectedColors: string[];
  setSelectedColors: React.Dispatch<React.SetStateAction<string[]>>;
  selectedStyles: string[];
  setSelectedStyles: React.Dispatch<React.SetStateAction<string[]>>;
  selectedDepartments: string[];
  setSelectedDepartments: React.Dispatch<React.SetStateAction<string[]>>;
}

const calculateDaysSinceSale = (statusChangedAt: string): number | null => {
  const saleDate = new Date(statusChangedAt);
  const now = new Date();
  return isNaN(saleDate.getTime())
    ? null
    : Math.floor((now.getTime() - saleDate.getTime()) / (1000 * 60 * 60 * 24));
};

const calculateTimeToSell = (statusChangedAt: string, firstPublishedAt: string): number | null => {
  const saleDate = new Date(statusChangedAt);
  const publishDate = new Date(firstPublishedAt);

  if (isNaN(saleDate.getTime()) || isNaN(publishDate.getTime())) {
    return null;
  }

  return Math.floor((saleDate.getTime() - publishDate.getTime()) / (1000 * 60 * 60 * 24));
};

const transformDistributionToRows = (distribution: Distribution | undefined): DistributionRow[] => {
  if (!distribution) return [];

  const allKeys = new Set([
    ...Object.keys(distribution.sold || {}),
    ...Object.keys(distribution.available || {})
  ]);

  return Array.from(allKeys)
    .map(key => ({
      id: key,
      name: key,
      sold: distribution.sold[key] || 0,
      available: distribution.available[key] || 0
    }))
    .sort((a, b) => (b.sold + b.available) - (a.sold + a.available))
    .slice(0, 10);
};

const matchesStyle = (title: string, style: string): boolean => {
  const titleWords = title.toLowerCase().split(/\s+/);
  const styleWords = style.toLowerCase().split(/\s+/);

  console.log(styleWords);
  
  return styleWords.every(styleWord => 
    titleWords.some(titleWord => titleWord.includes(styleWord))
  );
};

export const useSearch = (): UseSearchReturn => {
  const [results, setResults] = useState<SearchResult | null>(null);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const initialSearchDone = useRef(false);
  const searchCache = useRef(new LocalCache());
  const navigate = useNavigate();

  const [sortField, setSortField] = useState('price');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [showAvailable, setShowAvailable] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedSizes, setSelectedSizes] = useState<string[]>([]);
  const [selectedColors, setSelectedColors] = useState<string[]>([]);
  const [selectedStyles, setSelectedStyles] = useState<string[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);

  const performSearch = useCallback(async (searchQuery: string) => {
    if (!searchQuery || searchQuery.trim() === "") return;

    setLoading(true);
    setError(null);
    setErrorCode(null);
    setShowSuggestions(false);

    try {
      let res = searchCache.current.get(searchQuery);

      if (res === null) {
        res = await search(searchQuery);
        searchCache.current.set(searchQuery, res);
      }

      setResults(res as SearchResult);
      setQuery(searchQuery);
    } catch (err: any) {
      setErrorCode(err?.response?.status || null);

      if (err?.response?.status === 429) {
        setError("Limit reached.");
      } else {
        setError("Something went wrong. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const updateURL = useCallback((searchQuery: string | null = null) => {
    const params = new URLSearchParams();
    
    if (searchQuery || query) {
      params.set('q', searchQuery || query);
    }
    
    if (selectedBrands.length) params.set('brands', selectedBrands.join(','));
    if (selectedStyles.length) params.set('styles', selectedStyles.join(','));
    if (selectedCategories.length) params.set('categories', selectedCategories.join(','));
    if (selectedSizes.length) params.set('sizes', selectedSizes.join(','));
    if (selectedColors.length) params.set('colors', selectedColors.join(','));
    if (selectedDepartments.length) params.set('departments', selectedDepartments.join(','));

    navigate(`?${params.toString()}`, { replace: true });
  }, [navigate, query, selectedBrands, selectedStyles, selectedCategories, selectedSizes, selectedColors, selectedDepartments]);

  useEffect(() => {
    if (!results || !results.filterOptions) return;

    const params = new URLSearchParams(window.location.search);
    
    const updateFilterFromParam = (param: string, setter: (values: string[]) => void, options: FilterOption[]) => {
      const values = params.get(param)?.split(',') ?? [];
      const validValues = values.filter(value => 
        options.some(option => option.slug === value)
      );
      if (validValues.length) setter(validValues);
    };

    updateFilterFromParam('brands', setSelectedBrands, results.filterOptions.brands);
    updateFilterFromParam('styles', setSelectedStyles, results.filterOptions.styles);
    updateFilterFromParam('categories', setSelectedCategories, results.filterOptions.categories);
    updateFilterFromParam('sizes', setSelectedSizes, results.filterOptions.sizes);
    updateFilterFromParam('colors', setSelectedColors, results.filterOptions.colors);
    updateFilterFromParam('departments', setSelectedDepartments, results.filterOptions.departments);
  }, [results]);

  useEffect(() => {
    if (!results) return;
    updateURL();
  }, [selectedBrands, selectedStyles, selectedCategories, selectedSizes, selectedColors, selectedDepartments, updateURL, results]);

  const handleSearch = useCallback((searchQuery: string = query) => {
    performSearch(searchQuery);
    updateURL(searchQuery);
  }, [query, performSearch, updateURL]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchQuery = params.get('q');

    if (searchQuery && !initialSearchDone.current) {
      try {
        const decodedQuery = decodeURIComponent(searchQuery);
        setQuery(decodedQuery);
        performSearch(decodedQuery);
      } catch (e) {
        setQuery(searchQuery);
        performSearch(searchQuery);
      }
      initialSearchDone.current = true;
    }
  }, [performSearch]);

  const getFilteredPosts = useCallback((posts: any[]) => {
    if (!posts) return [];
    
    return posts.filter(post => {
      const brandMatch = selectedBrands.length === 0 ||
        (post.brand_obj?.slug && selectedBrands.includes(post.brand_obj.slug));

      const categoryMatch = selectedCategories.length === 0 ||
        (post.category_features?.[0]?.slug && selectedCategories.includes(post.category_features[0].slug));

      const sizeMatch = selectedSizes.length === 0 ||
        (post.size && selectedSizes.includes(post.size.toLowerCase().replace(/\s+/g, '-')));

      const colorMatch = selectedColors.length === 0 ||
        (post.colors && post.colors.some(color => 
          selectedColors.includes(color.name.toLowerCase().replace(/\s+/g, '-'))
        ));

      const styleMatch = selectedStyles.length === 0 ||
        (post.title && selectedStyles.some(style => 
          matchesStyle(post.title, style)
        ));

      const departmentMatch = selectedDepartments.length === 0 ||
        (post.department?.slug && selectedDepartments.includes(post.department.slug));

      return brandMatch && categoryMatch && sizeMatch && colorMatch && styleMatch && departmentMatch;
    });
  }, [selectedBrands, selectedCategories, selectedSizes, selectedColors, selectedStyles, selectedDepartments]);

  const soldPosts = useMemo(() => {
    if (!results?.posts) return [];
    return getFilteredPosts(results.posts);
  }, [getFilteredPosts, results]);

  const availablePosts = useMemo(() => {
    if (!results?.availablePosts) return [];
    return getFilteredPosts(results.availablePosts);
  }, [getFilteredPosts, results]);

  const postGridData = useMemo(() => {
    if (!results || (!soldPosts && !availablePosts)) return [];

    const postsToUse = showAvailable ? availablePosts : soldPosts;
    if (!postsToUse) return [];

    return postsToUse.map(post => ({
      id: post.id,
      title: post.title,
      brand: post.brand,
      image: post.picture_url,
      price: post.price,
      description: post.description,
      daysSinceSale: showAvailable ? null : calculateDaysSinceSale(post.inventory?.status_changed_at),
      timeToSell: showAvailable ? null : calculateTimeToSell(
        post.inventory?.status_changed_at,
        post.first_published_at
      ),
      shares: post.aggregates?.shares || 0,
      comments: post.aggregates?.comments || 0,
      likes: post.aggregates?.likes || 0,
      active_buyer_offers: post.active_buyer_offers || 0,
      has_offer: post.has_offer,
    }))
      .sort((a, b) => {
        const sortValue: Record<string, () => number> = {
          price: () => sortDirection === 'asc' ? a.price - b.price : b.price - a.price,
          daysSinceSale: () => {
            if (a.daysSinceSale === null) return sortDirection === 'asc' ? 1 : -1;
            if (b.daysSinceSale === null) return sortDirection === 'asc' ? -1 : 1;
            return sortDirection === 'asc' ? a.daysSinceSale - b.daysSinceSale : b.daysSinceSale - a.daysSinceSale;
          },
          timeToSell: () => {
            if (a.timeToSell === null) return sortDirection === 'asc' ? 1 : -1;
            if (b.timeToSell === null) return sortDirection === 'asc' ? -1 : 1;
            return sortDirection === 'asc' ? a.timeToSell - b.timeToSell : b.timeToSell - a.timeToSell;
          },
          shares: () => sortDirection === 'asc' ? a.shares - b.shares : b.shares - a.shares,
          comments: () => sortDirection === 'asc' ? a.comments - b.comments : b.comments - a.comments,
          likes: () => sortDirection === 'asc' ? a.likes - b.likes : b.likes - a.likes,
        };

        return sortValue[sortField]?.() ?? 0;
      });
  }, [results, showAvailable, availablePosts, soldPosts, sortField, sortDirection]);

  const formatTimeSinceSale = useCallback((daysSinceSale: number | null): string => {
    if (daysSinceSale === null) return 'Recently';
    if (daysSinceSale === 0) return 'Today';
    if (daysSinceSale === 1) return '1 day ago';
    return `${daysSinceSale} days ago`;
  }, []);

  const formatTimeToSell = useCallback((timeToSell: number | null): string => {
    if (timeToSell === null) return 'Unknown';
    if (timeToSell === 0) return 'Same day';
    if (timeToSell === 1) return '1 day';
    return `${timeToSell} days`;
  }, []);

  const handleSort = useCallback((field: string) => {
    setSortDirection(prevDirection => (sortField === field && prevDirection === 'desc' ? 'asc' : 'desc'));
    setSortField(field);
  }, [sortField]);

  const distributions = useMemo<DistributionTables>(() => {
    if (!results) {
      return {
        colorRows: [],
        sizeRows: [],
        styleRows: [],
        categoryRows: [],
        departmentRows: [],
        featureRows: [],
      };
    }

    return {
      colorRows: transformDistributionToRows(results.colorDistribution),
      sizeRows: transformDistributionToRows(results.sizeDistribution),
      styleRows: transformDistributionToRows(results.styleTagDistribution),
      categoryRows: transformDistributionToRows(results.categoryDistribution),
      departmentRows: transformDistributionToRows(results.departmentDistribution),
      featureRows: transformDistributionToRows(results.categoryFeatureDistribution),
    };
  }, [results]);

  const stats = useMemo<Stat[]>(() => {
    if (!results || !soldPosts || !availablePosts) return [];

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    const sixtyDaysAgo = new Date();
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);

    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

    const recentSoldCount30 = soldPosts.filter(post => {
      const saleDate = new Date(post.inventory?.status_changed_at);
      return saleDate >= thirtyDaysAgo;
    }).length;

    const recentSoldCount60 = soldPosts.filter(post => {
      const saleDate = new Date(post.inventory?.status_changed_at);
      return saleDate >= sixtyDaysAgo;
    }).length;

    const recentSoldCount90 = soldPosts.filter(post => {
      const saleDate = new Date(post.inventory?.status_changed_at);
      return saleDate >= ninetyDaysAgo;
    }).length;

    const totalListings30 = recentSoldCount30 + (availablePosts.length || 0);
    const totalListings60 = recentSoldCount60 + (availablePosts.length || 0);
    const totalListings90 = recentSoldCount90 + (availablePosts.length || 0);

    const sellThroughRate30 = totalListings30 > 0
      ? Math.round((recentSoldCount30 / totalListings30) * 100)
      : 0;

    const sellThroughRate90 = totalListings60 > 0
      ? Math.round((recentSoldCount90 / totalListings90) * 100)
      : 0;

    const saturationRate = availablePosts.length && recentSoldCount90
      ? Math.round((availablePosts.length / recentSoldCount90) * 100)
      : 0;

    const filteredAverageTimeToSell = soldPosts.reduce((acc, post) => {
      const timeToSell = calculateTimeToSell(
        post.inventory?.status_changed_at,
        post.first_published_at
      );
      return timeToSell ? acc + timeToSell : acc;
    }, 0) / soldPosts.length || 0;

    const filteredAverageSoldPrice = soldPosts.length > 0
      ? soldPosts.reduce((acc, post) =>
        acc + (typeof post.price === 'number' ? post.price : 0),
        0) / soldPosts.filter(post => typeof post.price === 'number').length
      : 0;

    const filteredAverageAvailablePrice = availablePosts.length > 0
      ? availablePosts.reduce((acc, post) =>
        acc + (typeof post.price === 'number' ? post.price : 0),
        0) / availablePosts.filter(post => typeof post.price === 'number').length
      : 0;

    const determineInsight = (sold: number, available: number, threshold: number = 2): { label: string; level: 'success' | 'warning' | 'danger' } => {
      const highDemandRatio = 1.5
      if (sold > available * highDemandRatio) {
        return { label: 'High Demand', level: 'success' };
      }
      if (sold > available) {
        console.log('Good Demand', sold, available);
        return { label: 'Good Demand', level: 'success' };
      }
      if (available > sold * threshold) {
        return { label: 'Oversaturated', level: 'danger' };
      }
      return { label: 'Balanced Market', level: 'warning' };
    };

    const determinePriceInsight = (soldPrice: number, availablePrice: number): { label: string; level: 'success' | 'warning' | 'danger' } => {
      if (availablePrice > soldPrice * 1.2) {
        return { label: 'Overpriced Market', level: 'danger' };
      }
      if (availablePrice < soldPrice * 0.8) {
        return { label: 'Underpriced Market', level: 'warning' };
      }
      return { label: 'Balanced Pricing', level: 'success' };
    };

    const determineTimeToSellInsight = (timeToSell: number | null): { label: string; level: 'success' | 'warning' | 'danger' } => {
      if (timeToSell === null) return { label: 'Insufficient Data', level: 'warning' };
      if (timeToSell <= 7) return { label: 'Very Fast Moving', level: 'success' };
      if (timeToSell <= 14) return { label: 'Fast Moving', level: 'success' };
      if (timeToSell <= 90) return { label: 'Moderate', level: 'warning' };
      return { label: 'Slow Moving', level: 'danger' };
    };

    const determineSellThroughInsight = (rate: number): { label: string; level: 'success' | 'warning' | 'danger' } => {
      if (rate >= 80) return { label: 'High Sell-Through', level: 'success' };
      if (rate >= 70) return { label: 'Good Sell-Through', level: 'success' };
      if (rate >= 55) return { label: 'Fair Sell-Through', level: 'warning' };
      return { label: 'Low Sell-Through', level: 'danger' };
    };

    const determineSaturationInsight = (rate: number): { label: string; level: 'success' | 'warning' | 'danger' } => {
      if (rate <= 30) return { label: 'Low Competition', level: 'success' };
      if (rate <= 75) return { label: 'Moderate Competition', level: 'warning' };
      if (rate <= 100) return { label: 'High Competition', level: 'danger' };
      return { label: 'Overly Competitive', level: 'danger' };
    };

    return [
      {
        title: 'Sell-Through Rate',
        mainValue: `${sellThroughRate30}%`,
        secondaryValue: `${sellThroughRate90}%`,
        mainLabel: 'Last 30 Days',
        secondaryLabel: 'Last 90 Days',
        description: 'A key performance indicator showing how quickly items are selling. Rates above 75% within 90 days indicate a healthy market, while rates below 30% suggest challenging selling conditions.',
        insight: {
          ...determineSellThroughInsight(sellThroughRate90)
        },
      },
      {
        title: 'Recent Listings',
        mainValue: recentSoldCount90 >= 500 ? '500+' : recentSoldCount90,
        secondaryValue: availablePosts.length >= 500 ? '500+' : availablePosts.length,
        mainLabel: 'Sold last 90 days',
        secondaryLabel: 'Available',
        description: 'Compare the volume of sold vs. available listings in the last 90 days. A healthy market typically shows more sold than available items. If available listings greatly outnumber sold ones, consider this a warning sign of slow-moving inventory.',
        insight: {
          ...determineInsight(recentSoldCount90, availablePosts.length)
        }
      },
      {
        title: 'Average Time to Sell',
        mainValue: `${Math.round(filteredAverageTimeToSell)} days`,
        mainLabel: 'Last 30 Days', // This is technically not true because its using all sold posts, not just the last 30 days
        description: 'The typical time items take to sell. Use this to set expectations for your inventory turnover and cash flow. Items taking longer than this average might need price adjustments or improved listings.',
        insight: {
          ...determineTimeToSellInsight(filteredAverageTimeToSell)
        },
      },
      {
        title: 'Market Saturation',
        mainValue: `${saturationRate}%`,
        mainLabel: 'Saturation',
        description: 'Ratio of available listings to sold listings. A high percentage (>100%) suggests a saturated market with more competition. A low percentage (<50%) indicates less competition and potentially faster sales.',
        insight: {
          ...determineSaturationInsight(saturationRate)
        },
      },
      {
        title: 'Average Price',
        mainValue: formatTotalPrice(filteredAverageSoldPrice),
        secondaryValue: formatTotalPrice(filteredAverageAvailablePrice),
        mainLabel: 'Sold',
        // Start of Selection
        secondaryLabel: 'Available',
        description: 'Compare actual selling prices vs. current listing prices. If available prices are significantly higher than sold prices, the market may be overpriced. Consider pricing closer to the sold average for faster sales.',
        insight: {
          ...determinePriceInsight(filteredAverageSoldPrice, filteredAverageAvailablePrice)
        }
      },
    ];
  }, [results, soldPosts, availablePosts]);

  return {
    results,
    stats,
    query,
    loading,
    error,
    errorCode,
    showSuggestions,
    setQuery,
    setShowSuggestions,
    handleSearch,
    performSearch,
    initialSearchDone,
    postGrid: {
      postGridData,
      formatTimeSinceSale,
      formatTimeToSell,
      sortField,
      sortDirection,
      handleSort,
      showAvailable,
      setShowAvailable
    },
    distributions,
    filterOptions: results?.filterOptions ?? {
      brands: [],
      categories: [],
      sizes: [],
      colors: [],
      styles: [],
      departments: [],
    },
    selectedBrands,
    setSelectedBrands,
    selectedCategories,
    setSelectedCategories,
    selectedSizes,
    setSelectedSizes,
    selectedColors,
    setSelectedColors,
    selectedStyles,
    setSelectedStyles,
    selectedDepartments,
    setSelectedDepartments,
  };
}; 